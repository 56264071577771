<template>
  <div style="width: 100%; height: 100%;">
    <iframe id="eurekers-school" style="width: 100%; height: 100%;"></iframe>
  </div>
</template>

<script>
import {mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
export default {
  mixins: [APICaller],
  name:'EurekersSchool',
  data() {
    return {
      iframe_url: '',
    };
  },
  computed:{
    ...mapGetters(["_g_User"]),
  },
  mounted() {
    this.InitOnlineCourse();
  },
  methods: {
    InitOnlineCourse() {
      
      let success = response => {
        this.iframe_url = response.data;
        var schoolFrame = document.getElementById("eurekers-school");
        schoolFrame.src = this.iframe_url;
      }

      let url = '/api/v1/users/user/get-online-page-info/' + this._g_User.online_course_id;
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('314', 'Error al recuperar los atributos de usuario.');

      this._getAPICall(url, successHandler, failureHandler);
    },
  },
};
</script>

<style scoped>
h3 {
  color: rgb(102, 102, 102);
}

</style>
